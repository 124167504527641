<template>
  <v-card class="pa-2" color="white">
    <v-row class="ma-0" justify="center">
      <v-form>
        <v-row justify="center" class="ma-0">
          <v-col cols="12">
            <p class="headline font-weight-bold mt-3">
              {{
                action == "create" ? "Configurar nivel de" : "Editar nivel de"
              }}
              Submembresía
            </p>
          </v-col>
          <v-col cols="12" :sm="showFiscalInfo ? '6' : '12'">
            <v-sheet outlined rounded="lg">
              <span
                class="
                  subtitle-1
                  font-weight-bold
                  text-center
                  relative-top
                  backgrond-color
                  pa-1
                "
              >
                Informacion de Acceso
              </span>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="affiliation.name"
                  label="Nombre"
                  filled
                  rounded
                  class="wc-body-2 font-weight-bold"
                />
              </v-col>
              <div class="row col-12 pr-0">
                <v-col cols="6" class="py-0">
                  <v-select
                    v-model="affiliation.period"
                    class="wc-body-2 font-weight-bold"
                    label="Periodo"
                    filled
                    rounded
                    :items="[{ text: 'Anual', value: 'YEAR' }]"
                  />
                </v-col>
                <v-col cols="6" class="py-0 px-0">
                  <v-text-field
                    v-model="affiliation.price"
                    class="wc-body-2 font-weight-bold"
                    label="$ Precio"
                    type="number"
                    filled
                    rounded
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="py-0 ml-3 pb-0"
                  v-if="
                    affiliation.period == 'YEAR' &&
                    user.role == 'HEAD_OFFICE' &&
                    !affiliation.expirationDatesConfig.isStaticInactive &&
                    !affiliation.expirationDatesConfig.isStaticExpiration
                  "
                >
                  <span class="wc-body-2 font-weight-bold">
                    Por defecto expira en un año
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  class="py-0 ml-3 hr_color"
                  v-if="
                    affiliation.period == 'YEAR' && user.role == 'HEAD_OFFICE'
                  "
                >
                  <v-switch
                    v-model="affiliation.expirationDatesConfig.isStaticInactive"
                    class="wc-body-2 font-weight-bold"
                    label="Agregar fecha de inactivación"
                  />
                  <div
                    class="row px-2 wc-body-2 font-weight-bold"
                    v-if="affiliation.expirationDatesConfig.isStaticInactive"
                  >
                    Agrega el mes y día.
                    <div class="pb-3">
                      <strong> Inactivar</strong> al usuario el
                      <strong> {{ dateInactiveFormatted }} </strong>
                      <span
                        v-if="
                          affiliation.expirationDatesConfig.dateInactive !== ''
                        "
                      >
                        de cada año</span
                      >.
                    </div>
                    <div>
                      <v-dialog
                        ref="dialog"
                        v-model="modalExpiratiDateConfig.isOpenInabilited"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateInactiveFormatted"
                            label="Fecha para inactivar"
                            prepend-icon="mdi-calendar"
                            readonly
                            @click:clear="
                              affiliation.expirationDatesConfig.dateInactive =
                                ''
                            "
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="
                            affiliation.expirationDatesConfig.dateInactive
                          "
                          active-picker="MONTH"
                        >
                          <v-spacer />
                          <v-btn
                            text
                            color="primary"
                            @click="
                              affiliation.expirationDatesConfig.dateInactive =
                                '';
                              modalExpiratiDateConfig.isOpenInabilited = false;
                            "
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              modalExpiratiDateConfig.isOpenInabilited = false
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  class="py-0 ml-3 hr_color"
                  v-if="
                    affiliation.period == 'YEAR' && user.role == 'HEAD_OFFICE'
                  "
                >
                  <v-switch
                    v-model="
                      affiliation.expirationDatesConfig.isStaticExpiration
                    "
                    label="Agregar fecha de expiración"
                    class="wc-body-2 font-weight-bold"
                  />
                  <div
                    class="row px-2 wc-body-2 font-weight-bold"
                    v-if="affiliation.expirationDatesConfig.isStaticExpiration"
                  >
                    Agrega el mes y día.
                    <div class="pb-3">
                      <strong>Expira</strong> al usuario el
                      <strong
                        >{{ dateExpiredFormatted }}
                        {{ expireDescription }}</strong
                      >.
                    </div>
                    <div>
                      <v-dialog
                        ref="dialog"
                        v-model="modalExpiratiDateConfig.isOpenexpired"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateExpiredFormatted"
                            label="Fecha para expirar"
                            prepend-icon="mdi-calendar"
                            readonly
                            @click:clear="
                              affiliation.expirationDatesConfig.dateExpired = ''
                            "
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>

                        <v-date-picker
                          v-model="
                            affiliation.expirationDatesConfig.dateExpired
                          "
                          active-picker="MONTH"
                        >
                          <v-spacer />
                          <v-btn
                            text
                            color="primary"
                            @click="
                              affiliation.expirationDatesConfig.dateExpired =
                                '';
                              modalExpiratiDateConfig.isOpenexpired = false;
                            "
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              modalExpiratiDateConfig.isOpenexpired = false
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </div>
                  </div>
                </v-col>
              </div>
              <v-col cols="12" class="py-0">
                <v-textarea
                  v-model="affiliation.details"
                  label="Detalles"
                  filled
                  rounded
                  class="wc-body-2 font-weight-bold"
                />
              </v-col>
            </v-sheet>
          </v-col>

          <v-col cols="12" sm="6" v-if="showFiscalInfo">
            <v-sheet outlined rounded="lg">
              <span
                class="
                  subtitle-1
                  font-weight-bold
                  text-center
                  relative-top
                  backgrond-color
                  pa-1
                "
              >
                Datos Fiscales de Acceso
              </span>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="Clave de Unidad"
                  filled
                  rounded
                  v-model="affiliation.billingInformation.unitKey"
                  class="wc-body-2 font-weight-bold"
                  @keyup="uppercase"
                />
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="No. de identificación"
                  filled
                  rounded
                  v-model="affiliation.billingInformation.number_of_id"
                  class="wc-body-2 font-weight-bold"
                />
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="Nombre de Unidad"
                  filled
                  rounded
                  v-model="affiliation.billingInformation.unitName"
                  class="wc-body-2 font-weight-bold"
                />
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="Clave de Producto"
                  filled
                  rounded
                  v-model="affiliation.billingInformation.productKey"
                  class="wc-body-2 font-weight-bold"
                />
              </v-col>
              <v-col cols="12" class="py-0">
                <v-textarea
                  v-model="affiliation.billingInformation.description"
                  label="Descripción o Concepto"
                  filled
                  rounded
                  class="wc-body-2 font-weight-bold"
                />
              </v-col>
            </v-sheet>
          </v-col>
        </v-row>
      </v-form>
    </v-row>
    <v-row class="ma-2" justify="end">
      <v-btn rounded>
        <span class="mx-5" @click="handleClose()">Cerrar</span>
      </v-btn>
      <v-btn color="primary" class="ml-3" rounded @click="createAffiliation()">
        <span class="mx-5">Guardar</span>
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState, mapMutations } from "vuex";
import Endpoints from "@/share/Endpoints";
export default {
  name: "SubAffiliationForm",
  props: {
    action: {
      type: String,
      default: "create",
    },
    affiliationProp: {
      type: Object,
      default: () => ({}),
    },
    showFiscalInfo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalExpiratiDateConfig: {
        isOpenInabilited: false,
        isOpenexpired: false,
      },
      affiliation: {
        id: "",
        name: "",
        price: "",
        details: "",
        period: "",
        affiliation: "",
        typeId: "",
        totalQuantity: "",
        itemQuantity: "",
        billingInformation: {
          description: "",
          productKey: "",
          number_of_id: "",
          unitKey: "",
          unitName: "",
        },
        expirationDatesConfig: {
          isStaticInactive: false,
          isStaticExpiration: false,
          dateInactive: "",
          dateExpired: "",
        },
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    dateInactiveFormatted() {
      if (this.affiliation.expirationDatesConfig.dateInactive !== "") {
        return moment(
          this.affiliation.expirationDatesConfig.dateInactive
        ).format("DD MMMM");
      }
      return "Seleccione una fecha";
    },
    dateExpiredFormatted() {
      if (this.affiliation.expirationDatesConfig.dateExpired !== "") {
        return moment(
          this.affiliation.expirationDatesConfig.dateExpired
        ).format("DD MMMM");
      }
      return "Seleccione una fecha";
    },
    expireDescription() {
      if (this.affiliation.expirationDatesConfig.dateExpired !== "") {
        if (
          this.affiliation.expirationDatesConfig.dateInactive !== "" &&
          moment(this.affiliation.expirationDatesConfig.dateExpired).format(
            "YYYY-MM-DD"
          ) <
            moment(this.affiliation.expirationDatesConfig.dateInactive).format(
              "YYYY-MM-DD"
            )
        ) {
          return "después que se inactive";
        } else if (
          this.affiliation.expirationDatesConfig.dateInactive !== "" &&
          moment(this.affiliation.expirationDatesConfig.dateExpired).format(
            "YYYY-MM-DD"
          ) >
            moment(this.affiliation.expirationDatesConfig.dateInactive).format(
              "YYYY-MM-DD"
            )
        ) {
          return "de cada año";
        } else if (
          this.affiliation.expirationDatesConfig.dateInactive !== "" &&
          moment(this.affiliation.expirationDatesConfig.dateExpired).format(
            "YYYY-MM-DD"
          ) ==
            moment(this.affiliation.expirationDatesConfig.dateInactive).format(
              "YYYY-MM-DD"
            )
        ) {
          return "el mismo día que se inactiva";
        } else if (this.affiliation.expirationDatesConfig.dateInactive == "") {
          return "de cada año";
        }
      }
      return "";
    },
  },
  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    uppercase() {
        this.affiliation.billingInformation.unitKey = this.affiliation.billingInformation.unitKey.toUpperCase();
    },
    async createAffiliation() {
      try {
        this.loading();
        const payload = {
          affiliationType: {
            ...this.affiliation,
            levelId: this.affiliationProp._id,
          },
        };
        const response = await this.axios.post(
          Endpoints.affiliationTypesCreate,
          payload
        );
        if (response.status > 199 && response.status < 300) {
          this.show({
            text: "Registro creado correctamente",
            color: "primary",
          });
          this.handleClose();
        }
      } catch (error) {
        this.show({
          text: "Ha ocurrido un problema al crear el registro",
          color: "error",
        });
      } finally {
        Object.assign(this.affiliation, {
          id: "",
          name: "",
          price: "",
          details: "",
          period: "",
          affiliation: "",
          typeId: "",
          totalQuantity: "",
          itemQuantity: "",
          billingInformation: {
            description: "",
            productKey: "",
            number_of_id: "",
            unitKey: "",
            unitName: "",
          },
          expirationDatesConfig: {
            isStaticInactive: false,
            isStaticExpiration: false,
            dateInactive: "",
            dateExpired: "",
          },
        });
        this.loaded();
      }
    },
    handleClose() {
      this.$emit("close");
      Object.assign(this.affiliation, {
        id: "",
        name: "",
        price: "",
        details: "",
        period: "",
        affiliation: "",
        typeId: "",
        totalQuantity: "",
        itemQuantity: "",
        billingInformation: {
          description: "",
          productKey: "",
          number_of_id: "",
          unitKey: "",
          unitName: "",
        },
        expirationDatesConfig: {
          isStaticInactive: false,
          isStaticExpiration: false,
          dateInactive: "",
          dateExpired: "",
        },
      });
    },
  },
  watch: {
    affiliationProp() {
      if (this.action === "edit") {
        this.affiliation = this.affiliationProp;
      }
      this.affiliation.affiliation = this.affiliationProp.affiliation;
    },
  },
  created() {
    if (this.action === "edit") {
      this.affiliation = this.affiliationProp;
    }
    this.affiliation.affiliation = this.affiliationProp.affiliation;
  },
};
</script>

<style>
.relative-top {
  position: relative;
  top: -15px;
  left: 20px;
}
.backgrond-color {
  background-color: #ffffff !important;
}
</style>