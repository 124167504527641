<template>
  <v-container fluid>
    <v-card color="px-0 py-7 rounded-xl elevation-0">
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab href="#sales">Ventas</v-tab>
            <v-tab href="#membershipLevels">Niveles de membresía</v-tab>
            <v-tab href="#coupons">Cupones</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="sales">
              <sales :affiliationId="affiliationId" />
            </v-tab-item>
            <v-tab-item value="membershipLevels">
              <access :affiliationId="affiliationId" />
            </v-tab-item>
            <v-tab-item value="coupons">
              <coupons :affiliationId="affiliationId" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { Access, Coupons, Sales } from './tabs/index';

export default {
  components: {
    Access,
    Coupons,
    Sales
  },
  props: ['affiliationId'],
  data: () => ({
    tab: 'sales'
  }),
};
</script>
