<template>
  <v-row justify="center">
    <v-dialog
      :width="width"
      v-model="open"
      scrollable
      persistent
      @click:outside="_close"
    >
      <v-card>
        <v-card-text class="pa-2">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" v-if="showLogo" class="d-flex justify-center">
                <v-img src="@/assets/logo.png" max-width="50" max-height="50"></v-img>
              </v-col>
              <v-col cols="12" class="pb-0">
                <slot name="content" ></slot>
              </v-col>
            </v-row>
              <v-row
                class="d-flex pb-2 mt-5"
                :class="
                  $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-left px-4'
                "
              >
                <action-button
                  :btnClass="secondaryBtnProps.class"
                  :variant="secondaryBtnProps.color"
                  :textColor="secondaryBtnProps.textColor"
                  v-show="secondaryBtnProps.show"
                  :elevation="secondaryBtnProps.elevation"
                  :disabled="secondaryBtnProps.disabled"
                  @click="$emit('close')"
                  :class="$vuetify.breakpoint.smAndUp||'mb-4'"
                >
                  {{ secondaryBtnProps.text }}
                </action-button>
                <action-button
                  :btnClass="primaryBtnProps.class"
                  :variant="primaryBtnProps.color"
                  :textColor="primaryBtnProps.textColor"
                  v-show="primaryBtnProps.show"
                  :elevation="primaryBtnProps.elevation"
                  :disabled="primaryBtnProps.disabled"
                  @click="$emit('continue')"
                >
                  {{ primaryBtnProps.text }}
                </action-button>
              </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import ActionButton from '@/components/shared/ActionButton';

export default {
  components: {
    ActionButton
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    showLogo: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '700'
    },
    primaryBtnProps: {
      type: Object,
      default: () => ({
        show: false,
        text: 'Cerrar',
        textColor: 'black',
        color: 'accent',
        elevation: null,
        disabled: false,
      })
    },
    secondaryBtnProps: {
      type: Object,
      default: () => ({
        show: false,
        text: 'Continuar',
        textColor: 'black',
        color: 'secondary',
        elevation: null,
        disabled: false,
      })
    }
  },
methods: {
  _close(){
    this.$emit('close')
  }
},
}
</script>