<template>
  <v-card>
    <v-card-text>
      <v-form
        ref="updateDiplomaForm"
        v-model="validUpdateDiplomaForm"
        lazy-validation
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <slot name="title">
                <p class="title font-weight-bold text-center px-7 pt-5">
                  Asigna una constancia para este nivel de membresía
                </p>
              </slot>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <p class="text-justify subtitle-2 primary--text">
                Carga tu plantilla en formato de imagen JPG con dimensiones de
                3301x2550 pixeles para orientación horizontal y de 2550x3301
                pixeles para orientación vertical. Recuerda dejar espacio para
                ubicar la información del asistente.
              </p>
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="diplomaTemplateComputed"
                :accept="['image/jpg', 'image/jpeg', 'image/png']"
                placeholder="Selecciona un archivo"
                prepend-inner-icon="mdi-image-area"
                prepend-icon=""
                filled
                rounded
                :rules="diplomaFileRules"
                show-size
                @click:clear="closeConstancyDialog(true)"
                @change="getImage(changePicture)"
              >
              </v-file-input>
            </v-col>
          </v-row>

          <!-- Dropdown para seleccionar el campo a modificar solo si company es igual -->
          <v-row v-if="company === '66fb2d896646ca001cb93b3f'">
            <v-col cols="12">
              <v-select
                v-model="selectedField"
                :items="['Nombre', 'No. de Socio', 'Grupo Local']"
                label="Selecciona el campo a modificar"
                filled
                rounded
                hide-details
              ></v-select>
            </v-col>
          </v-row>

          <!-- Mostrar controles de Nombre siempre, independientemente del valor de company -->
          <v-row v-if="selectedField === 'Nombre' || company !== '66fb2d896646ca001cb93b3f'">
            <v-col cols="12">
              <v-text-field
                filled
                rounded
                hide-details
                v-model="testName"
                label="Nombre"
              ></v-text-field>
              <v-row>
                <v-col cols="6">
                  <span>Alineación vertical: {{ marginTopName }} px</span>
                  <v-slider
                    v-model.number="marginTopName"
                    min="20"
                    :max="maxMarginTop"
                    hide-details
                  ></v-slider>
                </v-col>
                <v-col cols="6">
                  <span>Alineación Horizontal: {{ marginLeftName }} px</span>
                  <v-slider
                    v-model.number="marginLeftName"
                    min="10"
                    :max="maxMarginLeft"
                    hide-details
                  ></v-slider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Mostrar "No. de Socio" y "Grupo Local" solo si company es igual -->
          <v-row v-if="company === '66fb2d896646ca001cb93b3f' && selectedField === 'No. de Socio'">
            <v-col cols="12">
              <v-text-field
                readonly
                filled
                rounded
                hide-details
                v-model="memberNumber"
                label="No. de Socio"
              ></v-text-field>
              <v-row>
                <v-col cols="6">
                  <span>Alineación vertical: {{ marginTopMemberNumber }} px</span>
                  <v-slider
                    v-model.number="marginTopMemberNumber"
                    min="20"
                    :max="maxMarginTop"
                    hide-details
                  ></v-slider>
                </v-col>
                <v-col cols="6">
                  <span>Alineación Horizontal: {{ marginLeftMemberNumber }} px</span>
                  <v-slider
                    v-model.number="marginLeftMemberNumber"
                    min="10"
                    :max="orientationComputed === 'horizontal' ? 296 : 197"
                    hide-details
                  ></v-slider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-if="company === '66fb2d896646ca001cb93b3f' && selectedField === 'Grupo Local'">
            <v-col cols="12">
              <v-text-field
                readonly
                filled
                rounded
                hide-details
                v-model="localGroup"
                label="Grupo Local"
              ></v-text-field>
              <v-row>
                <v-col cols="6">
                  <span>Alineación vertical: {{ marginTopLocalGroup }} px</span>
                  <v-slider
                    v-model.number="marginTopLocalGroup"
                    min="20"
                    :max="maxMarginTop"
                    hide-details
                  ></v-slider>
                </v-col>
                <v-col cols="6">
                  <span>Alineación Horizontal: {{ marginLeftLocalGroup }} px</span>
                  <v-slider
                    v-model.number="marginLeftLocalGroup"
                    min="10"
                    :max="maxMarginLeft"
                    hide-details
                  ></v-slider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Vista previa de la constancia -->
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <v-img
                :max-width="
                  orientationComputed == 'horizontal' ? '462px' : '357px'
                "
                :height="
                  orientationComputed == 'horizontal' ? '357px' : '462px'
                "
                :src="objectURL"
                class="rounded-lg"
                lazy-src="https://picsum.photos/id/11/10/6"
              >
                <!-- Textos de Nombre, No. de Socio, Grupo Local -->
                <div
                  :style="`position: relative; text-align: center; color: ${textColor}; font-weight: bold;`"
                >
                  <!-- Nombre -->
                  <div
                    :style="`
                      position: absolute;
                      border: ${showSpace ? '1px solid red' : ''};
                      top: ${marginTopName}px;
                      left: ${marginLeftName}px;
                      font-size: 11px;
                      line-height: 13px;
                    `"
                  >
                    {{ testName }}
                  </div>

                  <!-- No. de Socio -->
                  <div
                    v-if="company === '66fb2d896646ca001cb93b3f'"
                    :style="`
                      position: absolute;
                      border: ${showSpace ? '1px solid red' : ''};
                      top: ${marginTopMemberNumber}px;
                      left: ${marginLeftMemberNumber}px;
                      font-size: 11px;
                      line-height: 13px;
                    `"
                  >
                    {{ memberNumber }}
                  </div>

                  <!-- Grupo Local -->
                  <div
                    v-if="company === '66fb2d896646ca001cb93b3f'"
                    :style="`
                      position: absolute;
                      border: ${showSpace ? '1px solid red' : ''};
                      top: ${marginTopLocalGroup}px;
                      left: ${marginLeftLocalGroup}px;
                      font-size: 11px;
                      line-height: 13px;
                    `"
                  >
                    {{ localGroup }}
                  </div>
                </div>
              </v-img>
            </v-col>
          </v-row>

          <v-row
            class="d-flex pb-2 mt-5"
            :class="
              $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
            "
          >
            <v-btn
              class="font-weight-bold black--text mx-2"
              color="grey lighten-3"
              rounded
              elevation="0"
              @click="closeConstancyDialog()"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="font-weight-bold black--text mx-2"
              color="accent"
              rounded
              elevation="0"
              :disabled="
                objectURL == 'https://via.placeholder.com/300x300.png?text=W' ||
                marginTopName == '' ||
                marginLeftName == '' ||
                marginTopMemberNumber == '' ||
                marginLeftMemberNumber == '' ||
                marginTopLocalGroup == '' ||
                marginLeftLocalGroup == '' ||
                !validUpdateDiplomaForm
              "
              @click="saveConstancy"
            >
              Guardar
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>





<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "UpdateDiplomaDialog",
  props: {
    marginTop: {
      type: Number,
      default: 0,
    },
    marginLeft: {
      type: Number,
      default: 0,
    },
    nameDelimeter: {
      type: Number,
      default: 0,
    },
    textColor: {
      type: String,
      default: "black",
    },
    orientation: {
      type: String,
      default: "horizontal",
    },
    objectURL: {
      type: String,
      default: "https://via.placeholder.com/300x300.png?text=W",
    },
    diplomaTemplate: {
      type: File,
      default: null,
    },
  },
  data() {
    return {
      company:"",
      memberNumber: "633f39789a286cc23f082e14",
      localGroup: "Grupo Local XYZ",
      selectedField: "Nombre",
      marginTopName: 50,
      marginLeftName: 50,
      marginTopMemberNumber: 100,
      marginLeftMemberNumber: 50,
      marginTopLocalGroup: 150,
      marginLeftLocalGroup: 50,

      validUpdateDiplomaForm: false,
      dimentions: [3301, 2550],
      testName: "Diego Manuel Ortiz Arenas",
      showSpace: true,
      diplomaFileRules: [
        (value) => {
          if (value && value.size) {
            return (
              !value ||
              value.size < 5000000 ||
              "El archivo no debe de pesar más de 5MB"
            );
          } else {
            return true;
          }
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user, // Asegúrate de que tu Vuex tenga el módulo `user`
    }),
    dynamicFields() {
      switch (this.selectedField) {
        case "Nombre":
          return [this.testName];
        case "No. de Socio":
          return [this.memberNumber];
        case "Grupo Local":
          return [this.localGroup];
        default:
          return [this.testName];
      }
    },
    nameDelimiterMax() {
      if (this.marginLeftComputed <= 10) {
        return 442;
      } else if (this.marginLeftComputed >= 320) {
        return 131;
      } else {
        const range = 320 - 10;
        const scale = (this.marginLeftComputed - 10) / range;
        return 442 - (442 - 131) * scale;
      }
    },
    maxMarginTop() {
    return this.orientationComputed === "horizontal" ? 335 - 10 : 440 - 10; 
  },
  maxMarginLeft() {
    return this.orientationComputed === "horizontal" ?  388 - 10 : 278 - 10; 
  },
    marginTopComputed: {
      get() {
        return this.marginTop;
      },
      set(value) {
        this.$emit("setMarginTop", value);
      },
    },
    marginLeftComputed: {
      get() {
        return this.marginLeft;
      },
      set(value) {
        this.$emit("setMarginLeft", value);
      },
    },
    nameDelimeterComputed: {
      get() {
        return this.nameDelimeter;
      },
      set(value) {
        this.$emit("setNameDelimeter", value);
      },
    },
    textColorComputed: {
      get() {
        return this.textColor;
      },
      set(value) {
        this.$emit("setTextColor", value);
      },
    },
    orientationComputed: {
      get() {
        return this.orientation;
      },
      set(value) {
        this.$emit("setOrientation", value);
      },
    },
    diplomaTemplateComputed: {
      get() {
        return this.diplomaTemplate;
      },
      set(value) {
        this.$emit("setDiplomaTemplate", value);
      },
    },
    objectURLComputed: {
      get() {
        return this.objectURL;
      },
      set(value) {
        this.$emit("setObjectURL", value);
      },
    },
  },
  mounted() {

    this.company = this.user.id
  if (localStorage.getItem('marginTopName')) {
    this.marginTopName = Number(localStorage.getItem('marginTopName'));
  }
  if (localStorage.getItem('marginLeftName')) {
    this.marginLeftName = Number(localStorage.getItem('marginLeftName'));
  }
  if (localStorage.getItem('marginTopMemberNumber')) {
    this.marginTopMemberNumber = Number(localStorage.getItem('marginTopMemberNumber'));
  }
  if (localStorage.getItem('marginLeftMemberNumber')) {
    this.marginLeftMemberNumber = Number(localStorage.getItem('marginLeftMemberNumber'));
  }
  if (localStorage.getItem('marginTopLocalGroup')) {
    this.marginTopLocalGroup = Number(localStorage.getItem('marginTopLocalGroup'));
  }
  if (localStorage.getItem('marginLeftLocalGroup')) {
    this.marginLeftLocalGroup = Number(localStorage.getItem('marginLeftLocalGroup'));
  }
}
,
  methods: {
    ...mapState("user", ["indicators", "user"]),
    ...mapMutations("notification", ["show"]),
    closeConstancyDialog(close) {
      this.$emit("closeConstancyDialog", close);
    },
    
      saveConstancy() {
  localStorage.setItem('marginTopName', this.marginTopName);
  localStorage.setItem('marginLeftName', this.marginLeftName);
  localStorage.setItem('marginTopMemberNumber', this.marginTopMemberNumber);
  localStorage.setItem('marginLeftMemberNumber', this.marginLeftMemberNumber);
  localStorage.setItem('marginTopLocalGroup', this.marginTopLocalGroup);
  localStorage.setItem('marginLeftLocalGroup', this.marginLeftLocalGroup);
  
  this.$emit("saveConstancy");


    },

    getImage(callback) {
      setTimeout(() => {
        if (this.diplomaTemplateComputed) {
          const blob = URL.createObjectURL(this.diplomaTemplateComputed);
          let img = new Image();
          img.src = blob;
          img.onload = function () {
            callback(this.width, this.height, blob);
          };
        }
      }, 100);
    },
    changePicture(x, y, url) {
      if (this.dimentions.includes(x) && this.dimentions.includes(y)) {
        x > y
          ? (this.orientationComputed = "horizontal")
          : (this.orientationComputed = "vertical");
        this.objectURLComputed = url;
      } else {
        this.show({
          text: "Las medidas de la imagen no son apropiadas para la constancia, Algunas veces pueder ser la orientación",
          color: "error",
        });
        this.diplomaTemplateComputed = null;
      }
    },
  },
};
</script>

<style>
</style>


