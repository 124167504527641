<template>
  <v-row class="mt-5" justify="center">
    <modal-template @close="handleCloseDialog" @continue="handleSaveForm" :open="isFormDialogOpen" width="1200"
      :primaryBtnProps="{
        text: 'Guardar',
        show: true,
        class: 'ma-2',
        elevation: '0',
        disabled: !validAffiliationForm,
      }" :secondaryBtnProps="{
        text: 'Cerrar',
        color: 'secondary',
        show: true,
        class: 'ma-2',
        elevation: '0',
      }">
      <template #content>
        <v-form v-model="validAffiliationForm" ref="affiliationForm">
          <v-row>
            <v-col cols="12" class="mt-5">
              <p class="headline font-weight-bold text-center my-0">
                {{ formTitles[formMode] || "" }}
              </p>
            </v-col>
            <v-col cols="12" sm="4">
              <v-row>
                <v-col cols="12" class="mt-3">
                  <p class="subtitle-1 font-weight-bold text-center mb-0">
                    Inscripción
                  </p>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-checkbox :disabled="affiliation.disablePrice" hide-details
                        v-model="affiliation.inscriptionConfig.active" :label="`Agregar pago de inscripción`"
                        class="mt-0">
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field filled rounded dense type="number" :step="0.01" :rules="!affiliation.inscriptionConfig.active
                        ? []
                        : priceRules
                        " v-model.number="affiliation.inscriptionConfig.price" :disabled="!affiliation.inscriptionConfig.active ||
                          affiliation.disablePrice
                          " label="$ Cuota de inscripción" style="color: black">
                      </v-text-field>
                      <div v-if="!affiliation.subMembership"
                        class="d-flex rounded-lg px-6 mb-4 align-center justify-space-between switch_field">
                        <span style="font-size: 15px">Fijar cuota por nivel de submembresía</span>
                        <v-checkbox :disabled="!affiliation.inscriptionConfig.active" :ripple="false" v-model="affiliation.inscriptionConfig.staticPriceForSub
                          "></v-checkbox>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-text-field label="Clave de Unidad" filled rounded :disabled="!affiliation.inscriptionConfig.active ||
                    affiliation.disablePrice
                    " v-model="affiliation.inscriptionBillingInfo.unitKey" @input="
                      affiliation.inscriptionBillingInfo.unitKey =
                      affiliation.inscriptionBillingInfo.unitKey.toUpperCase()
                      " @blur="fetchUnitDescription('inscriptionBillingInfo')" />
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-text-field label="No. de identificación" filled rounded :disabled="!affiliation.inscriptionConfig.active ||
                    affiliation.disablePrice
                    " v-model="affiliation.inscriptionBillingInfo.number_of_id" />
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field label="Nombre de Unidad" filled readonly rounded :disabled="!affiliation.inscriptionConfig.active ||
                    affiliation.disablePrice
                    " v-model="affiliation.inscriptionBillingInfo.unitName" />
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field label="Clave de Producto" filled rounded :disabled="!affiliation.inscriptionConfig.active ||
                    affiliation.disablePrice
                    " v-model="affiliation.inscriptionBillingInfo.productKey" />
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea v-model="affiliation.inscriptionBillingInfo.description" label="Descripción o Concepto"
                    filled rounded :disabled="!affiliation.inscriptionConfig.active ||
                      affiliation.disablePrice
                      " hide-details />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4" class="pa-0">
              <v-row>
                <v-col cols="12" :sm="showFiscalInfo ? '12' : '12'">
                  <v-col cols="12">
                    <p class="subtitle-1 font-weight-bold text-center">
                      Informacion de Acceso
                    </p>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field v-model="affiliation.name" label="Nombre" filled rounded
                      :rules="affiliationTypeNameRules" />
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-select v-model="affiliation.period" label="Periodo de renovación" filled rounded :items="periods"
                      :rules="affiliationTypePeriodRules" />
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field v-model="affiliation.price" label="$ Precio" type="number" :step="0.01" filled rounded
                      :rules="affiliationTypePriceRules" />
                  </v-col>
                  <v-col cols="12" class="py-0 ml-3 mb-5" v-if="
                    !affiliation.expirationDatesConfig.isStaticInactive &&
                    affiliation.period
                  ">
                    Por defecto expira en
                    {{
                      affiliation.period == "YEAR"
                        ? "1 año"
                        : affiliation.period == "MONTH"
                          ? "1 mes"
                          : "3 meses"
                    }}
                  </v-col>
                  <v-col cols="12" class="py-0 mb-5" v-if="affiliation.period && !isAmpiBranch">
                    <div class="hr_color px-4">
                      <v-switch v-model="affiliation.expirationDatesConfig.isStaticInactive
                        " label="Agregar dia de inactivación" />
                      <div class="row px-2" v-if="
                        affiliation.expirationDatesConfig.isStaticInactive
                      ">
                        Agrega el mes y día.
                        <div class="pb-3">
                          <strong> Inactivar</strong> al usuario el
                          <strong> {{ dateInactiveFormatted }} </strong>
                          <span v-if="
                            affiliation.expirationDatesConfig.dateInactive !==
                            ''
                          ">
                            de cada año</span>.
                        </div>
                        <div class="mb-4 mt-2" v-if="affiliation.period == 'YEAR'">
                          <v-select v-model="affiliation.expirationDatesConfig
                            .inactivationMonth
                            " :items="months" label="Mes" filled rounded hide-details>
                          </v-select>
                        </div>
                        <div class="mb-4 mt-2">
                          <v-text-field v-model="affiliation.expirationDatesConfig.inactivationDay
                            " label="Día" filled rounded hide-details type="number" min="1" max="31">
                          </v-text-field>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" class="py-0 mb-5" v-if="affiliation.period && !isAmpiBranch">
                    <v-row>
                      <v-col cols="12">
                        <div class="hr_color px-4">
                          <v-switch v-model="affiliation.indulgenceConfig.active" label="Agregar tolerancia" />
                          <div class="row px-2" v-if="affiliation.indulgenceConfig.active">
                            Agrega el tiempo de tolerancia.
                            <div class="mb-4 mt-2">
                              <v-row class="ma-0">
                                <v-col class="pa-1" cols="5">
                                  <v-text-field v-model="affiliation.indulgenceConfig.periodQuantity
                                    " filled rounded hide-details type="number" min="1">
                                  </v-text-field>
                                </v-col>

                                <v-col class="pa-1" cols="7">
                                  <v-select v-model="affiliation.indulgenceConfig.period" :items="[
                                    { text: 'Días', value: 'DAYS' },
                                    { text: 'Meses', value: 'MONTHS' },
                                  ]" filled rounded hide-details>
                                  </v-select>
                                </v-col>

                              </v-row>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="py-0 mb-5" v-if="affiliation.period && !isAmpiBranch">
                    <v-row>
                      <v-col cols="12">
                        <div class="hr_color px-4">
                          <v-switch v-model="affiliation.prepaidConfig.active" label="Activar pago anticipado" />
                          <div class="row px-2" v-if="affiliation.prepaidConfig.active">
                            Configura con cuanto tiempo de anticipación te podrán pagar.
                            <div class="mb-4 mt-2">
                              <v-row class="ma-0">
                                <v-col class="pa-1" cols="5">
                                  <v-text-field v-model="affiliation.prepaidConfig.periodQuantity
                                    " filled rounded hide-details type="number" min="1">
                                  </v-text-field>
                                </v-col>

                                <v-col class="pa-1" cols="7">
                                  <v-select v-model="affiliation.prepaidConfig.period" :items="[
                                    { text: 'Días', value: 'DAYS' },
                                    { text: 'Meses', value: 'MONTHS' },
                                  ]" filled rounded hide-details>
                                  </v-select>
                                </v-col>

                              </v-row>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-textarea v-model="affiliation.details" label="Detalles" filled rounded />
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4" v-if="showFiscalInfo">
              <v-row>
                <v-col cols="12">
                  <p class="subtitle-1 font-weight-bold text-center">
                    Datos Fiscales de Acceso
                  </p>
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-text-field label="Clave de Unidad" filled rounded v-model="affiliation.billingInformation.unitKey"
                    @input="
                      affiliation.billingInformation.unitKey =
                      affiliation.billingInformation.unitKey.toUpperCase()
                      " @blur="fetchUnitDescription('billingInformation')" />
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-text-field label="No. de identificación" filled rounded
                    v-model="affiliation.billingInformation.number_of_id" />
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field label="Nombre de Unidad" filled readonly rounded
                    v-model="affiliation.billingInformation.unitName" />
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field label="Clave de Producto" filled rounded
                    v-model="affiliation.billingInformation.productKey" />
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea v-model="affiliation.billingInformation.description" label="Descripción o Concepto" filled
                    rounded hide-details />
                </v-col>
                <v-col>
                  <div class="hr_color px-4">
                    <v-switch v-model="affiliation.firstTimeMemberFiscalData.active"
                      label="Agregar datos fiscales para nuevos miembros" />
                    <div class="row px-0" v-if="affiliation.firstTimeMemberFiscalData.active">
                      <v-col cols="6" class="py-0">
                        <v-text-field label="Clave de Unidad" filled rounded
                          v-model="affiliation.firstTimeMemberFiscalData.unitKey" @input="
                            affiliation.firstTimeMemberFiscalData.unitKey =
                            affiliation.firstTimeMemberFiscalData.unitKey.toUpperCase()
                            " @blur="fetchUnitDescription('firstTimeMemberFiscalData')" />
                      </v-col>
                      <v-col cols="6" class="py-0">
                        <v-text-field label="No. de identificación" filled rounded
                          v-model="affiliation.firstTimeMemberFiscalData.number_of_id" />
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-text-field label="Nombre de Unidad" filled readonly rounded
                          v-model="affiliation.firstTimeMemberFiscalData.unitName" />
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-text-field label="Clave de Producto" filled rounded
                          v-model="affiliation.firstTimeMemberFiscalData.productKey" />
                      </v-col>
                      <v-col cols="12" class="py-0 mb-4">
                        <v-textarea v-model="affiliation.firstTimeMemberFiscalData.description"
                          label="Descripción o Concepto" filled rounded hide-details />
                      </v-col>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </modal-template>
    <v-col cols="12" class="d-flex justify-end py-5" v-if="
      user.permissions.Membership &&
      user.permissions.Membership.update == true
    ">
      <action-button @click="handleOpenCreateForm">
        <span>
          <v-icon> mdi-plus </v-icon>
          Nuevo nivel de membresía
        </span>
      </action-button>
    </v-col>
    <v-col cols="12" v-for="affiliation in affiliations" :key="affiliation._id">
      <MembershipLevel :level="affiliation" @update="handleOpenUpdateForm" @delete="handleOpenDeleteDialog"
        @assign-items="handleAssignItemsDialog" @constancy="handleConstancyDialog" @reload="handleReload">
      </MembershipLevel>
    </v-col>
    <modal-template :open="isDeleteDialogOpen" @close="handleCloseDeleteDialog" @continue="deleteAffiliation"
      :showLogo="true" :primaryBtnProps="{
        text: 'Eliminar',
        show: true,
        class: 'ma-2',
        elevation: '0',
      }" :secondaryBtnProps="{
        text: 'Cancelar',
        color: 'secondary',
        show: true,
        class: 'ma-2',
        elevation: '0',
      }">
      <template #content>
        <!--<div class="d-flex flex-column align-center">
          <p class="text-h3">Eliminar nivel de membresía</p>
          <p class="text-subtitle-1">¿Estás seguro que quieres eliminar el registro "{{ affiliation.name }}"?</p>
        </div>-->
        <v-row>
          <v-col cols="12" class="mt-2">
            <p class="text-center font-weight-bold headline mb-0">
              Eliminar nivel de membresía
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="title text-center">
              ¿Estás seguro que quieres eliminar el registro "{{
                affiliation.name
              }}"?
            </p>
          </v-col>
        </v-row>
      </template>
    </modal-template>
    <modal-template :open="isAssignItemsDialogOpen" @continue="assignAffiliationItems()"
      @close="isAssignItemsDialogOpen = false" :primaryBtnProps="{
        text: 'Aceptar',
        show: true,
        class: 'ma-2',
        elevation: '0',
      }" :secondaryBtnProps="{
        text: 'Cancelar',
        color: 'secondary',
        show: true,
        class: 'ma-2',
        elevation: '0',
      }">
      <template #content>
        <v-container class="px-0">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <p class="title font-weight-black text-center px-7 pt-5">
                Selecciona la cantidad de créditos que se asignarán a este nivel
                de membresía
              </p>
            </v-col>
            <v-col cols="10">
              <v-slider v-model="affiliation.itemQuantity" :max="indicators.unassignedItems"
                :min="affiliation.totalQuantity * -1" thumb-color="secondary" thumb-label="always"
                loader-height="20"></v-slider>
            </v-col>
            <v-col cols="8" class="ma-0">
              <v-sheet elevation="2" rounded="lg" class="pa-2 pb-4">
                <v-row class="ma-0 pa-2" justify="center">
                  <span class="text-center font-weight-black wc-subtitle-2">
                    Para la membresía: {{ affiliation.name }}
                  </span>
                </v-row>

                <v-row class="ma-0 pa-2" justify="space-between" align="center">
                  <span class="text-center font-weight-bold">
                    Créditos que estás asignando
                  </span>
                  <br />
                  <v-col cols="4" md="3" lg="2" class="pa-0">
                    <v-text-field v-model="affiliation.itemQuantity" class="text-center font-weight-black"
                      hide-details="auto" type="number" dense solo reverse hide-spin-buttons>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="ma-0 pa-2" justify="space-between">
                  <span class="text-center font-weight-bold">
                    Créditos que ya tienes asignados
                  </span>
                  <br />
                  <span class="text-center font-weight-black">
                    {{ affiliation.totalQuantity }}
                  </span>
                </v-row>

                <v-row class="ma-0 pa-2" justify="space-between">
                  <span class="text-center font-weight-bold">
                    Total de créditos asignados
                  </span>
                  <br />
                  <span class="text-center font-weight-black">
                    {{ affiliation.totalQuantity + affiliation.itemQuantity }}
                  </span>
                </v-row>

                <v-divider></v-divider>

                <v-row class="ma-0 pa-2 pt-4" justify="space-between">
                  <span class="text-center font-weight-bold">
                    Créditos disponibles en tu cuenta
                  </span>
                  <br />
                  <span class="text-center font-weight-black">
                    {{ indicators.unassignedItems - affiliation.itemQuantity }}
                  </span>
                </v-row>
                <v-row class="ma-0 pa-2 pt-4" justify="space-between">
                  <span class="text-center font-weight-bold">
                    Créditos gratuitos disponibles en tu cuenta
                  </span>
                  <br />
                  <span class="text-center font-weight-black">
                    {{ indicators.unassignedItemsFree }}
                  </span>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </modal-template>
    <v-dialog v-model="constancyDialog" width="900" @click:outside="closeConstancyDialog()">
      <ConstancyUploader :marginTop="diplomaConfiguration.marginTop" :marginLeft="diplomaConfiguration.marginLeft"
        :nameDelimeter="diplomaConfiguration.nameDelimeter" :orientation="diplomaConfiguration.orientation"
        :textColor="diplomaConfiguration.textColor" :objectURL="diplomaConfiguration.objectURL"
        :diplomaTemplate="diplomaConfiguration.diplomaTemplate" @setMarginTop="setMarginTop"
        @setMarginLeft="setMarginLeft" @setNameDelimeter="setNameDelimeter" @setOrientation="setOrientation"
        @setTextColor="setTextColor" @setDiplomaTemplate="setDiplomaTemplate" @setObjectURL="setObjectURL"
        @closeConstancyDialog="closeConstancyDialog" @saveConstancy="saveConstancy"></ConstancyUploader>
    </v-dialog>
  </v-row>
</template>
<script>
import qs from "query-string";
import moment from "moment";
import ActionButton from "@/components/shared/ActionButton";
import ModalTemplate from "@/components/shared/ModalTemplate";
import MembershipLevel from "@/components/dashboard/affiliation/MembershipLevels/MembershipLevel";
import Endpoints from "@/share/Endpoints";
import ConstancyUploader from "@/components/dashboard/affiliation/Access/ConstancyUploader";
import { validations } from "@/share/validations.js";
import { formatPrice } from "@/utils";
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";

export default {
  components: {
    ActionButton,
    ModalTemplate,
    MembershipLevel,
    ConstancyUploader,
  },
  props: ["affiliationId"],
  data: () => ({
    months: [
      { text: "Enero", value: 1 },
      { text: "Febrero", value: 2 },
      { text: "Marzo", value: 3 },
      { text: "Abril", value: 4 },
      { text: "Mayo", value: 5 },
      { text: "Junio", value: 6 },
      { text: "Julio", value: 7 },
      { text: "Agosto", value: 8 },
      { text: "Septiembre", value: 9 },
      { text: "Octubre", value: 10 },
      { text: "Noviembre", value: 11 },
      { text: "Diciembre", value: 12 },
    ],
    constancyDialog: false,
    diplomaConfiguration: {
      accessId: "",
      orientation: "horizontal",
      marginTop: 400,
      marginLeft: 220,
      nameDelimeter: 700,
      diplomaTemplate: null,
      objectURL: "https://via.placeholder.com/300x300.png?text=W",
      textColor: "black",
      event: "",
    },
    validAffiliationForm: false,
    modalExpiratiDateConfig: {
      isOpenInabilited: false,
      isOpenexpired: false,
    },
    affiliation: {
      _id: "",
      name: "",
      price: "",
      details: "",
      period: "",
      affiliation: "",
      typeId: "",
      totalQuantity: "",
      itemQuantity: "",
      inscriptionConfig: {
        price: 0,
        active: false,
        staticPriceForSub: false,
      },
      inscriptionBillingInfo: {
        description: "",
        productKey: "",
        number_of_id: "",
        unitKey: "",
        unitName: "",
      },
      firstTimeMemberFiscalData: {
        description: "",
        productKey: "",
        number_of_id: "",
        unitKey: "",
        unitName: "",
        active: false
      },
      billingInformation: {
        description: "",
        productKey: "",
        number_of_id: "",
        unitKey: "",
        unitName: "",
      },
      expirationDatesConfig: {
        isStaticInactive: false,
        isStaticExpiration: false,
        dateInactive: "",
        dateExpired: "",
        inactivationMonth: 1,
        inactivationDay: 1,
      },
      indulgenceConfig: {
        active: false,
        period: "MONTHS",
        periodQuantity: 0,
      },
      prepaidConfig: {
        active: false,
        period: "MONTHS",
        periodQuantity: 0,
      },
    },
    affiliationObj: {
      id: "",
      name: "",
      price: "",
      details: "",
      period: "",
      affiliation: "",
      typeId: "",
      totalQuantity: "",
      itemQuantity: "",
    },
    formMode: "",
    isFormDialogOpen: false,
    isDeleteDialogOpen: false,
    isAssignItemsDialogOpen: false,
    dateFormat: "YYYY-MM-DD HH:mm",
    affiliations: [],
    affiliationTotalDocs: 0,
    tableLoader: false,
    tableOptions: {},
    searchData: {
      page: 1,
      itemsPerPage: 10,
    },
    cols: [
      {
        text: "Nombre",
        value: "name",
        align: "center",
      },
      {
        text: "Vendidos/Disponibles",
        value: "totalQuantity",
        align: "center",
      },
      {
        text: "Periodo",
        value: "period",
        align: "center",
      },
      {
        text: "Precio",
        value: "formattedPrice",
        align: "center",
      },
      {
        text: "Detalle",
        value: "details",
        align: "center",
      },
      {
        text: "Estatus",
        value: "status",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
      },
    ],
    periods: [
      { text: "Anual", value: "YEAR" },
      { text: "Mensual", value: "MONTH" },
      { text: "Trimestral", value: "TRIMESTER" },
    ],
    formTitles: {
      CREATE: "Crear nivel de membresía",
      UPDATE: "Editar nivel de membresía",
    },
    // Validaciones

    affiliationTypeNameRules: validations.generalMValidation({
      name: "nombre",
      required: true,
    }),
    affiliationTypePeriodRules: validations.generalMValidation({
      name: "periodo de renovación",
      required: true,
    }),
    affiliationTypePriceRules: validations.numberMValidation({
      name: "precio",
      required: true,
    }),
    inscriptionPriceRules: validations.generalFValidation({
      name: "cuota de inscripción",
      required: true,
    }),
    billingDescriptionRules: validations.generalFValidation({
      name: "descripción",
      required: false,
    }),
    priceRules: validations.numberFValidation({
      name: "cuota de inscripción",
      required: false,
    }),
  }),
  computed: {
    ...mapState("user", ["indicators", "user"]),
    ...mapState("affiliation", ["affiliationSelected"]),
    isAmpiBranch() {
      return this.user.headOffice === "6297b0fec7bf8b6f826313be";
    },
    showFiscalInfo() {
      if (this.formMode === "CREATE") {
        return true; //this.affiliationSelected.offerBillfing;
      } else {
        return this.affiliation.hasOwnProperty("billingInformation");
      }
    },
    dateInactiveFormatted() {
      if (this.affiliation.expirationDatesConfig.isStaticInactive) {
        if (this.affiliation.period == "YEAR") {
          let date = moment(
            `${this.affiliation.expirationDatesConfig.inactivationMonth}-${this.affiliation.expirationDatesConfig.inactivationDay}`,
            "MM-DD"
          );
          return date.format("DD MMMM");
        } else {
          if (this.affiliation.period == "MONTH") {
            return `${this.affiliation.expirationDatesConfig.inactivationDay} de cada mes`;
          } else {
            return `${this.affiliation.expirationDatesConfig.inactivationDay} de cada trimestre`;
          }
        }
      }
      return "Seleccione una fecha";
    },
    dateExpiredFormatted() {
      if (this.affiliation.expirationDatesConfig.dateExpired !== "") {
        return moment(
          this.affiliation.expirationDatesConfig.dateExpired
        ).format("DD MMMM");
      }
      return "Seleccione una fecha";
    },
    expireDescription() {
      if (this.affiliation.expirationDatesConfig.dateExpired !== "") {
        if (
          this.affiliation.expirationDatesConfig.dateInactive !== "" &&
          moment(this.affiliation.expirationDatesConfig.dateExpired).format(
            "YYYY-MM-DD"
          ) <
          moment(this.affiliation.expirationDatesConfig.dateInactive).format(
            "YYYY-MM-DD"
          )
        ) {
          return "después que se inactive";
        } else if (
          this.affiliation.expirationDatesConfig.dateInactive !== "" &&
          moment(this.affiliation.expirationDatesConfig.dateExpired).format(
            "YYYY-MM-DD"
          ) >
          moment(this.affiliation.expirationDatesConfig.dateInactive).format(
            "YYYY-MM-DD"
          )
        ) {
          return "de cada año";
        } else if (
          this.affiliation.expirationDatesConfig.dateInactive !== "" &&
          moment(this.affiliation.expirationDatesConfig.dateExpired).format(
            "YYYY-MM-DD"
          ) ==
          moment(this.affiliation.expirationDatesConfig.dateInactive).format(
            "YYYY-MM-DD"
          )
        ) {
          return "el mismo día que se inactiva";
        } else if (this.affiliation.expirationDatesConfig.dateInactive == "") {
          return "de cada año";
        }
      }
      return "";
    },
  },
  watch: {
    tableOptions: {
      async handler() {
        await this.searchAffiliationsData();
      },
      deep: true,
    },
    "affiliation.inscriptionConfig.active"(newVal) {
      if (!newVal) {
        this.affiliation.inscriptionConfig.staticPriceForSub = false;
        this.affiliation.inscriptionConfig.price = 0;
      }
    },
    "affiliation.firstTimeMemberFiscalData.active"(newVal) {
      if (!newVal) {
        let keyArrays = Object.keys(this.affiliation.firstTimeMemberFiscalData).filter(key => key != 'active')
        keyArrays.forEach((key) => {
          this.affiliation.firstTimeMemberFiscalData[key] = ''
        })
      }
    }
  },
  async created() {
    await this.getAffiliations();
  },
  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    ...mapActions("user", ["getIndicators"]),

    async fetchUnitDescription(param) {
      try {
        const response = await axios.post("invoices/validate-unit-name", {
          unitKey: this.affiliation[param].unitKey.toUpperCase(),
        });
        const unitNames = response.data.unitNames;

        if (unitNames.length > 0) {
          this.affiliation[param].unitName = unitNames[0].description;
        } else {
          this.affiliation[param].unitName = "";
        }
      } catch (error) {
        console.error(error);
      }
    },
    setMarginTop(value) {
      Object.assign(this.diplomaConfiguration, { marginTop: value });
    },
    setMarginLeft(value) {
      Object.assign(this.diplomaConfiguration, { marginLeft: value });
    },
    setNameDelimeter(value) {
      Object.assign(this.diplomaConfiguration, { nameDelimeter: value });
    },
    setOrientation(value) {
      Object.assign(this.diplomaConfiguration, { orientation: value });
    },
    setTextColor(value) {
      Object.assign(this.diplomaConfiguration, { textColor: value });
    },
    setDiplomaTemplate(value) {
      Object.assign(this.diplomaConfiguration, { diplomaTemplate: value });
    },
    setObjectURL(value) {
      Object.assign(this.diplomaConfiguration, { objectURL: value });
    },
    // normaliceDimentions(marginTop, marginLeft, nameDelimeter, orientation, unconvert=false){
    //   let ny = 0; let nx = 0; let nNd = 0; // ny: new marginTop; nx: new marginLeft; nNd: new nameDelimeter
    //   const scale = {
    //     xs: unconvert ? 2550 : 357,
    //     xl: unconvert ? 357 : 2550,
    //     ys: unconvert ? 3301 : 462,
    //     yl: unconvert ? 462 : 3301
    //   }

    //   if (orientation === 'horizontal')
    //   {
    //     ny = Math.round((marginTop * scale.xl) / scale.xs)
    //     nx = Math.round((marginLeft * scale.yl) / scale.ys)
    //     nNd = Math.round((nameDelimeter * scale.yl) / scale.ys)
    //   }
    //   else if (orientation === 'vertical')
    //   {
    //     ny = Math.round((marginTop * scale.yl) / scale.ys)
    //     nx = Math.round((marginLeft * scale.xl) / scale.xs)
    //     nNd = Math.round((nameDelimeter * scale.xl) / scale.xs)
    //   }
    //   return {ny: ny, nx: nx, nNd: nNd}
    // },
    async saveConstancy() {
      let { marginTop, marginLeft, nameDelimeter, orientation } =
        this.diplomaConfiguration;
      this.loading();
      try {
        const formData = new FormData();
        formData.append("orientation", orientation);
        if (this.diplomaConfiguration.diplomaTemplate) {
          formData.append(
            "diplomaTemplate",
            this.diplomaConfiguration.diplomaTemplate
          );
        }
        let { top, left } = {
          top: orientation == "horizontal" ? 2.34 : 2.56,
          left: orientation == "horizontal" ? 2.58 : 2.35,
        };
        formData.append("marginTop", Math.round(marginTop * top - 40));
        formData.append("marginLeft", Math.round(marginLeft * left));
        formData.append("nameDelimeter", Math.round(nameDelimeter * left));
        formData.append("textColor", this.diplomaConfiguration.textColor);
        formData.append("affiliation", this.$route.params.affiliationId);
        await this.axios({
          method: "PUT",
          url: `affiliation-types/${this.affiliation._id}/diploma-configuration`,
          data: formData,
        });
        this.loaded();
        this.show({
          text: "Constancia guardada correctamente",
          color: "success",
        });
      } catch (error) {
        console.log(error);
        this.loaded();
        this.show({
          text: "Error al guardar la constancia",
          color: "error",
        });
      } finally {
        this.closeConstancyDialog();
        await this.handleReload();
      }
    },
    closeConstancyDialog(close = false) {
      if (!close) {
        this.constancyDialog = false;
      }
      this.diplomaConfiguration = {
        accessId: "",
        orientation: "horizontal",
        marginTop: 400,
        marginLeft: 220,
        nameDelimeter: 700,
        diplomaTemplate: null,
        objectURL: "https://via.placeholder.com/300x300.png?text=W",
        textColor: "black",
        event: "",
      };
    },
    handleConstancyDialog(item) {
      this.affiliation = item;
      let {
        marginTop = 400,
        marginLeft = 220,
        nameDelimeter = 700,
        diplomaTemplate,
        textColor,
        orientation,
      } = this.affiliation.diplomaConfiguration ?? this.diplomaConfiguration;

      //Las medidas se deducen a partir de los siguientes valores dependiendo de la orientación
      // if (attendee.orientation == 'horizontal') {
      //       height = "836px";
      //       width = "1191px";

      //   } else {
      //       height = "1182px";
      //       width = "840px";
      //   }
      //  let {ny, nx, nNd} = this.normaliceDimentions(marginTop, marginLeft, nameDelimeter, orientation, true)
      let { top, left } = {
        top: orientation == "horizontal" ? 2.34 : 2.56,
        left: orientation == "horizontal" ? 2.58 : 2.35,
      };
      this.setMarginTop(Math.round((marginTop + 40) / top));
      this.setMarginLeft(Math.round(parseInt(marginLeft) / left));
      this.setNameDelimeter(Math.round(parseInt(nameDelimeter) / left));
      this.setObjectURL(diplomaTemplate);
      this.setTextColor(textColor);
      this.setOrientation(orientation);
      this.constancyDialog = true;
    },
    async handleReload() {
      await this.getAffiliations();
    },

    /**
     * Función para formatear los niveles de membresi
     */
    formatLevels(levels) {
      /*
      Para cada uno de los niveles, se busca si tiene un levelId, si lo tiene, 
      se busca el nivel con el id y se le agrega el subnivel y se elimina el nivel
      */
      for (let i = 0; i < levels.length; i++) {
        if (levels[i].hasOwnProperty("levelId")) {
          for (let j = 0; j < levels.length; j++) {
            if (levels[i].levelId == levels[j]._id) {
              if (!levels[j].hasOwnProperty("subLevels")) {
                levels[j].subLevels = [];
              }
              levels[j].subLevels.push(levels[i]);
              levels.splice(i, 1);
              i--;
            }
          }
        }
      }
      return levels;
    },

    /**
     * Función para validar los campos del formulario
     */
    validateAffiliationForm() {
      if (this.$refs.affiliationForm.validate()) {
        this.snackbar = true;
        return true;
      }
      return false;
    },
    /*async handleUpdateOptions(opts) {
      this.tableOptions = {
        itemsPerPage: opts.itemsPerPage,
        page: opts.page
      };
      await this.getAffiliations();
    },*/
    handleCloseDialog() {
      this.isFormDialogOpen = false;
      this.formMode = "";
      Object.assign(this.affiliation, {
        _id: "",
        name: "",
        price: "",
        details: "",
        period: "",
        affiliation: "",
        typeId: "",
        totalQuantity: "",
        itemQuantity: "",
        inscriptionConfig: {
          price: 0,
          active: false,
          staticPriceForSub: false,
        },
        billingInformation: {
          description: "",
          productKey: "",
          number_of_id: "",
          unitKey: "",
          unitName: "",
        },
        inscriptionBillingInfo: {
          description: "",
          productKey: "",
          number_of_id: "",
          unitKey: "",
          unitName: "",
        },
        firstTimeMemberFiscalData: {
          description: "",
          productKey: "",
          number_of_id: "",
          unitKey: "",
          unitName: "",
          active: false
        },
        expirationDatesConfig: {
          isStaticInactive: false,
          isStaticExpiration: false,
          dateInactive: "",
          dateExpired: "",
        },
      });
      Object.assign(this.modalExpiratiDateConfig, {
        isOpenInabilited: false,
        isOpenexpired: false,
      });
    },
    handleOpenCreateForm() {
      this.isFormDialogOpen = true;
      this.formMode = "CREATE";
      this.affiliation.affiliation = this.affiliationId;
    },
    handleOpenUpdateForm({ item, disablePrice }) {
      this.isFormDialogOpen = true;
      this.formMode = "UPDATE";
      this.affiliation = {
        ...this.affiliation,
        subMembership: item.hasOwnProperty("levelId") ? true : false,
        name: item.name,
        details: item.details,
        period: item.period,
        price: item.price,
        affiliation: this.affiliationId,
        typeId: item._id,
        _id: item._id,
        indulgenceConfig: item.indulgenceConfig || {
          active: false,
          period: "MONTHS",
          periodQuantity: 0,
        },
        prepaidConfig: item.prepaidConfig || {
          active: false,
          period: "MONTHS",
          periodQuantity: 0,
        },
        disablePrice,
      };
      if (item.hasOwnProperty("billingInformation")) {
        this.affiliation.billingInformation = item.billingInformation;
      }
      if (item.hasOwnProperty("inscriptionBillingInfo")) {
        this.affiliation.inscriptionBillingInfo = item.inscriptionBillingInfo;
      }
      if (item.hasOwnProperty("expirationDatesConfig")) {
        this.affiliation.expirationDatesConfig = item.expirationDatesConfig;
      }
      if (item.hasOwnProperty("firstTimeMemberFiscalData")) {
        this.affiliation.firstTimeMemberFiscalData = item.firstTimeMemberFiscalData;
      }
      if (item.hasOwnProperty("inscriptionConfig")) {
        this.affiliation.inscriptionConfig = item.inscriptionConfig;
      }
    },
    handleOpenDeleteDialog(item) {
      this.isDeleteDialogOpen = true;
      this.affiliation = {
        ...this.affiliation,
        name: item.name,
        typeId: item._id,
      };
    },
    handleCloseDeleteDialog() {
      this.isDeleteDialogOpen = false;
      this.affiliation = {
        ...this.affiliation,
        name: "",
        typeId: "",
      };
    },
    handleAssignItemsDialog(item) {
      this.affiliation = {
        ...this.affiliation,
        name: item.name,
        typeId: item._id,
        totalQuantity: item.totalQuantity,
      };
      //console.log(this.affiliation);
      // Poner en cero el contador del slider cada que se abra el dialog
      this.affiliation.itemQuantity = 0;
      this.isAssignItemsDialogOpen = true;
    },
    formatAffiliationData(aff) {
      return {
        ...aff,
        formattedPrice: formatPrice(aff.price),
        createdAt: moment(aff.createdAt).format(this.dateFormat),
        updatedAt: moment(aff.updatedAt).format(this.dateFormat),
        //period: this.periods[aff.period] || 'N/A'
      };
    },
    async searchAffiliationsData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.tableOptions;
      this.searchData.page = page;
      this.searchData.itemsPerPage = itemsPerPage;
      await this.getAffiliations();
    },
    async getAffiliations() {
      this.loading();

      this.tableLoader = true;
      const endpoint = qs.stringifyUrl({
        url: Endpoints.affiliationTypes.replace(
          /:affiliationId/,
          this.affiliationId
        ),
        query: {
          page: this.searchData.page,
          limit: this.searchData.itemsPerPage,
        },
      });
      const response = await this.axios.get(endpoint);
      if (response.status === 200) {
        let { affiliationTypes } = response.data;
        this.affiliationTotalDocs = response.data.totalDocs;
        this.affiliations = affiliationTypes.map((aff) =>
          this.formatAffiliationData(aff)
        );
        this.affiliations = this.formatLevels(this.affiliations);
        //this.tableLoader = false;
      }
      this.tableLoader = false;
      this.loaded();
    },
    handleSaveForm() {
      const functions = {
        CREATE: this.createAffiliation,
        UPDATE: this.updateAffiliation,
      };
      functions[this.formMode] && functions[this.formMode]();
    },
    async createAffiliation() {
      if (this.validateAffiliationForm()) {
        try {
          this.loading();
          const payload = {
            affiliationType: {
              ...this.affiliation,
            },
          };
          const response = await this.axios.post(
            Endpoints.affiliationTypesCreate,
            payload
          );
          if (response.status > 199 && response.status < 300) {
            await this.getAffiliations();
            this.show({
              text: "Registro creado correctamente",
              color: "primary",
            });
            this.handleCloseDialog();
          }
        } catch (error) {
          this.show({
            text: "Ha ocurrido un problema al crear el registro",
            color: "error",
          });
        } finally {
          this.loaded();
        }
      }
    },
    async updateAffiliation() {
      if (this.validateAffiliationForm()) {
        try {
          this.loading();
          delete this.affiliation.disablePrice;
          const { typeId, ...rest } = this.affiliation;
          const payload = {
            affiliationType: {
              ...rest,
            },
          };
          const response = await this.axios.put(
            Endpoints.affiliationTypesUpdate.replace(/:typeId/, typeId),
            payload
          );
          if (response.status > 199 && response.status < 300) {
            await this.getAffiliations();
            this.show({
              text: "Registro editado correctamente",
              color: "primary",
            });
            this.handleCloseDialog();
            // TODO: show alert to let user know that the item has been updated successfully
          }
        } catch (error) {
          this.show({
            text: "Ha ocurrido un problema al editar el registro",
            color: "error",
          });
        } finally {
          this.loaded();
        }
      }
    },
    async updateAffiliationTypeStatus(affiliationType) {
      try {
        this.loading();
        //const { typeId, ...rest } = this.affiliation;
        const typeId = affiliationType._id;
        const payload = {
          affiliationType: {
            status: affiliationType.status,
            affiliation: affiliationType.affiliation,
          },
        };
        const response = await this.axios.put(
          Endpoints.affiliationTypesUpdate.replace(/:typeId/, typeId),
          payload
        );
        if (response.status > 199 && response.status < 300) {
          this.show({
            text: "Registro actualizado correctamente",
            color: "primary",
          });
        } else {
          this.show({
            text: "Ha ocurrido un problema al editar el registro",
            color: "error",
          });
        }
      } catch (error) {
        this.show({
          text: "Ha ocurrido un problema al editar el registro",
          color: "error",
        });
      } finally {
        this.loaded();
      }
    },
    async deleteAffiliation() {
      try {
        this.loading();
        const { typeId } = this.affiliation;
        const payload = {
          affiliationType: {
            affiliation: this.affiliationId,
          },
        };
        const response = await this.axios.delete(
          Endpoints.affiliationTypesUpdate.replace(/:typeId/, typeId),
          { data: payload }
        );
        if (response.status > 199 && response.status < 300) {
          await this.getAffiliations();
          this.show({
            text: "El registro ha sido eliminado correctamente",
            color: "primary",
          });
          this.handleCloseDeleteDialog();
          // TODO: show alert to let user know that register has been deleted
        }
      } catch (error) {
        this.show({
          text: "Ha ocurrido un problema al eliminar el registro",
          color: "error",
        });
      } finally {
        this.loaded();
      }
    },
    async assignAffiliationItems() {
      const { typeId } = this.affiliation;

      // Comprobar si se van a asignar o quitar registros del nivel de la membresía
      let isAssignment = 0;
      if (this.affiliation.itemQuantity > 0) {
        isAssignment = 1;
      } else if (this.affiliation.itemQuantity < 0) {
        isAssignment = -1;
      }
      if (isAssignment != 0) {
        // itemQuantity siempre tiene que ser un número positivo
        try {
          this.loading();
          const payload = {
            affiliationType: {
              itemQuantity: this.affiliation.itemQuantity * isAssignment,
              affiliation: this.affiliationId,
              unassignedItems: this.indicators.unassignedItems
            },
          };
          //console.log("Items assignment payload");
          //console.log(payload);
          let assignmentEndpoint =
            isAssignment > 0
              ? Endpoints.affiliationTypesAssignItems.replace(/:itemId/, typeId)
              : Endpoints.affiliationTypesUnassignItems.replace(
                /:itemId/,
                typeId
              );
          const response = await this.axios.put(assignmentEndpoint, {
            affiliationType: payload.affiliationType,
          });
          //console.log("Items assignment");
          //console.log(response);
          if (response.status > 199 && response.status < 300) {
            await this.getIndicators();
            this.getAffiliations();
            this.show({
              text: "Se han asignado los registros correctamente",
              color: "primary",
            });
            // Cerrar el modal
            this.isAssignItemsDialogOpen = false;
          }
        } catch (error) {
          this.show({
            text: "Ha ocurrido un problema al editar el registro",
            color: "error",
          });
        } finally {
          this.loaded();
        }
      } else {
        this.show({
          text: "No se realizó ningún cambio",
          color: "amber",
        });
        this.isAssignItemsDialogOpen = false;
      }
    },
    openAssignItemsDialog(affiliation) {
      this.affiliation.id = affiliation._id;
      this.affiliation.name = affiliation.name;
      this.affiliation.totalQuantity = affiliation.totalQuantity;
      // Poner en cero el contador del slider cada que se abra el dialog
      this.affiliation.itemQuantity = 0;
      //this.affiliation.event = this.$route.params.id;
      this.assignItemsDialog = true;
    },
  },
};
</script>
<style scoped>
.hr_color {
  border: 1px solid #c4c6c8;
}

.switch_field {
  background-color: #eaeaea;
  height: 52px;
  cursor: pointer;
}

.switch_field:hover {
  background-color: #dadada;
}
</style>
